/* src/components/LandingPage/LandingPage.css */

/* General Styles */
body {
    background: linear-gradient(145deg, #F5F7FA 0%, #FFFFFF 100%);
}

/* Hero Section Styling */
.hero-image {
    filter: brightness(0.9);
}

.hero-image:hover {
    transform: scale(1.05);
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    filter: brightness(1);
}

/* Feature Box Styling */
.feature-box {
    background: #FFFFFF;
    border-radius: 12px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(44, 62, 80, 0.1);
}

.feature-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 24px rgba(44, 62, 80, 0.15);
    border-color: #2C3E50;
}

/* Accordion Styling */
.accordion-summary {
    background-color: #F5F7FA;
    border-radius: 8px;
    padding: 12px 20px;
    transition: all 0.3s ease;
}

.accordion-summary:hover {
    background-color: #E74C3C;
    color: #FFFFFF;
}

/* Contact Form Styling */
form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

form .MuiTextField-root {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    backdrop-filter: blur(8px);
}

form button {
    align-self: flex-start;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    background: linear-gradient(135deg, #E74C3C 0%, #C0392B 100%);
}

form button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(231, 76, 60, 0.3);
}

/* Testimonial Cards */
.testimonial-card {
    background: linear-gradient(145deg, #FFFFFF 0%, #F5F7FA 100%);
    border: 1px solid rgba(44, 62, 80, 0.1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.testimonial-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 32px rgba(44, 62, 80, 0.12);
    border-color: #E74C3C;
}

/* Skip Navigation Link */
.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: linear-gradient(135deg, #E74C3C 0%, #C0392B 100%);
    color: white;
    padding: 12px 24px;
    z-index: 100;
    transition: all 0.3s ease;
    text-decoration: none;
    border-radius: 0 0 8px 0;
    font-weight: 500;
}

.skip-link:focus {
    top: 0;
    box-shadow: 0 4px 12px rgba(231, 76, 60, 0.3);
}

/* Feature Icons */
.feature-box .MuiAvatar-root {
    width: 64px;
    height: 64px;
    margin: 0 auto 20px auto;
    font-size: 28px;
    background: linear-gradient(135deg, #2C3E50 0%, #34495E 100%);
    box-shadow: 0 4px 12px rgba(44, 62, 80, 0.2);
    transition: all 0.3s ease;
}

.feature-box:hover .MuiAvatar-root {
    transform: scale(1.1);
    background: linear-gradient(135deg, #E74C3C 0%, #C0392B 100%);
}

/* Responsive Design */
@media (max-width: 960px) {
    .about-images-container {
        height: 300px;
    }
    
    .about-image {
        width: 50%;
    }
}

@media (max-width: 600px) {
    .about-images-container {
        height: auto;
        flex-direction: column;
        gap: 16px;
        perspective: none;
    }

    .about-image {
        position: static;
        width: 85%;
        margin-bottom: 20px;
    }
}

/* Animation Classes */
.fade-in {
    animation: fadeIn 0.5s ease-in;
}

.slide-up {
    animation: slideUp 0.5s ease-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { 
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #F5F7FA;
}

::-webkit-scrollbar-thumb {
    background: #2C3E50;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #E74C3C;
}
